<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3">{{ $tc('dashboard.title', 2) }}</h1>
    <Resource :create-request="api.create"
              :delete-request="api.remove"
              :form-component="() => import('@/components/forms/DashboardForm.vue')"
              :index-request="api.index"
              :meta="{name: $tc('dashboard.title', 1), namePlural: $tc('dashboard.title', 2)}"
              :model-type="Dashboard"
              :show-request="api.show"
              :table-content="tableContent"
              :update-request="api.update"
              @row-click="goToDashboard"/>
  </v-container>
</template>

<script lang="js">
import Resource from '@/components/vuetifyResource/Resource.vue';
import eventBus from '@/eventBus';
import * as api from '@/api/endpoints/dashboard';
import Dashboard from '@/application/models/dashboard';

export default {
  name: 'DashboardTable',
  components: {
    Resource,
  },
  data() {
    return {
      api,
      Dashboard,
    };
  },
  computed: {
    tableContent() {
      return [
        {
          text: this.$t('dashboard.table.name'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'dashboards' },
        text: this.$tc('dashboard.title', 2),
      },
    ]);
  },
  methods: {
    goToDashboard(dashboardId) {
      this.$router.push({
        name: 'dashboard.show',
        params: { dashboardId: dashboardId },
      });
    },
  },
};
</script>
